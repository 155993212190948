<template>
  <b-card>
    <div v-if="linkHistory && !loading.first" class="text-right">
      <a class="lnk lnk-primary" :href="linkHistory" target="_blank" rel="noopener noreferrer"><feather-icon icon="ClockIcon"/> Historial</a>
    </div>
    <form-render
      ref="formRenderOrganization"
      :form.sync="formOrganization"
      :fields="fieldsOrganization"
      :key="keyFormRenderOrganizationBilling"
    />
    <form-render
      v-if="!loading.first"
      ref="formRenderManageDataCompany"
      :form.sync="form"
      :fields="fields"
      @send="saveOrUpdateBillingManageDataCompany"
      containerButtonsClass="col-sm-12"
      :key="keyFormRenderManageDataCompany"
    >
      <template #company_info>
        <h5>{{ $t("Datos de la empresa") }}</h5>
      </template>
      <template #billing_info>
        <h5 id="v-step-0">{{ $t("Datos de facturación") }}</h5>
      </template>
      <template #space >
      </template>
      <template #contacts>
        <div>

        <b-button
            v-b-tooltip.hover
            title="Añadir nuevo contacto"
            variant="outline-light push-right"
            class="ml-1"
            :disabled="disableAddEmail2Btn"
            @click="toogleModalAddContacts"
        ><feather-icon icon="PlusIcon"
        /> Añadir contacto</b-button>
        </div>
        <table-render
            class="mx-01"
            :class="{'border-error' : showInvalidEmails}"
            id="table_emails"
            :rows="rowsEmails"
            :schema="schema"
            :actions="actions"
        />
      </template>
    </form-render>
    <b-button v-if="!loading.first" class="mt-1" @click="saveForm" :variant="buttonSend.color" :disabled="buttonSend.status"
      ><feather-icon :icon="!callService ? buttonSend.icon : 'LoaderIcon'" :class="callService && 'spinner'"/> {{ buttonSend.text }}</b-button>
    <form-skeleton v-show="loading.first"></form-skeleton>
    <modal-add-contacts :dataFact="manageDataCompany" :form-new-contact="formNewContact"  @completeData="pruebamilquinientos" @resetForm="resetFormContacts"></modal-add-contacts>
  </b-card>
</template>

<script>

import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import BillingService from '../../billing.service'
import formSkeleton from './components/formSkeleton.vue'
import Swal from 'sweetalert2'
import ModalAddContacts from './components/ModalAddContacts.vue'

export default {
  name: 'create-billing-manage-data',
  components: {formSkeleton, ModalAddContacts},
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      buttonSend: {
        icon: 'SaveIcon',
        color: 'warning',
        text: 'Crear datos de facturación',
        status: false
      },
      formNewContact: {},
      disableAddEmail2Btn: false,
      rowsEmails: [],
      changeToCreate: false,
      environment: null,
      linkHistory: null,
      form: {},
      formOrganization: {},
      formEmail: {},
      fields: [],
      fieldsOrganization: [],
      required_oc: [{ id: 'oc', text: 'OC' }],
      expirationDate: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString(),
      actions: [],
      paramId: null,
      fieldEmail: [],
      country: {
        chile: 'CL'
      },
      billingService: new BillingService(this),
      keyFormRender: 0,
      keyFormRenderManageDataCompany: 1,
      keyFormRenderOrganizationBilling: 100,
      companyDataBilling: {},
      showInvalidEmails: false,
      showEmptyTextarea: false,
      messageInvalidemail: '',
      loading: {
        shippersByOrganization: true,
        shipper: true,
        total: true,
        first: true,
        second: true
      },
      maxMonth: new Date(),
      inputMotive: { fieldType: 'FieldInput', name: 'status_description', useLabel: true, containerClass: 'col-sm-12 col-md-4 mb-0 container-info', disabled: true, useSkeleton: false },
      companyStatusOptions: [
        { id: 1, text:  this.$t('Activo') },
        { id: 2, text: this.$t('Inactivo') }
      ],
      activationStatusOptions: [],
      allowStatusType: null,
      callService: false,
      hasStatusUpdate: false,
      overdueDate: null,
      company_id_on_save: null,
      steps: [
        {

          target: '#v-step-0',
          content: 'Ahora puedes designar a la persona responsable de recibir notificaciones y tomar decisiones.',
          params: {
            placement: 'top'
          },
          header: {
            title: 'Nuevo <b>contacto</b>'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      manageDataCompany: 'getManageDataCompany',
      billingDataCompanyById: 'getBillingDataCompanyById',
      carrier: 'getCarrier',
      businessDataNotCreated: 'getBusinessDataNotCreated',
      shippers: 'getShippersByOrganization',
      shipper: 'getShipper',
      generalLoading: 'getLoading',
      activationStatusDataCompany: 'getActivationStatusDataCompany'

    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    activationStatusDataCompany() {
      this.activationStatusOptions = this.activationStatusDataCompany.data
    },
    generalLoading: {
      handler () {
        this.loading.shippersByOrganization = !!this.generalLoading.getShippersByOrganization
        this.loading.businessDataNotCreated = !!this.generalLoading.getBusinessDataNotCreated
        this.loading.shipper = !!this.generalLoading.getShipper
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRenderManageDataCompany++
      },
      deep: true
    },
    manageDataCompany() {
      const countryCode = this.manageDataCompany?.company?.country?.code
      this.form = {
        ...this.form,
        ...this.manageDataCompany,
        taxIdentificationNumber: countryCode === this.country.chile ? this.$options.filters.formatRut(this.manageDataCompany?.taxIdentificationNumber ?? '') : this.manageDataCompany?.taxIdentificationNumber,
        company_id: this.manageDataCompany?.company?.id,
        company_type: this.manageDataCompany?.company?.type?.platformName,
        company_name: this.manageDataCompany?.company?.name,
        company_status: { id: this.manageDataCompany?.company?.isActive ? 1 : 2 },
        status_description: this.manageDataCompany?.company?.activationStatusDesc,
        country: this.manageDataCompany?.company?.country.name
      }

      if (this.manageDataCompany?.overdue_payment_limit) {
        this.form.overdue_payment_limit = this.$options.filters.dateToFullDate(this.manageDataCompany.overdue_payment_limit)
        this.overdueDate = this.manageDataCompany.overdue_payment_limit
      }

      this.allowStatusType = this.manageDataCompany?.company?.isActive
      this.rowsEmails = this.manageDataCompany?.contacts?.map(contact => {
        return {
          id: contact.email,
          ...contact
        }
      })
      this.loading.first = false
      this.setDniLabel(countryCode || 'WORD_DNI')
      if (this.manageDataCompany?.company?.activationStatusDesc === 'Compromiso de Pago' && this.$route.params?.id) this.setOverduePaymentInput()
      // Check de Facturación Directa habilitado con compañías de tipo Seller solamente
      const directBillingIndex = this.fields.findIndex(el => el.name === 'directBilling')
      if (this.manageDataCompany?.company?.type?.id === 3) { // 3=Seller
        this.fields[directBillingIndex].disabled = false
      } else {
        this.fields[directBillingIndex].disabled = true
      }
    },
    businessDataNotCreated() {
      this.setFieldsProperties(this.fieldsOrganization, 'organization_id', 'options', this.businessDataNotCreated, true)
    },
    shippers() {
      this.setFieldsProperties(this.fieldsOrganization, 'shipper_id', 'options', this.shippers, true)
    },
    shipper() {
      this.setDniLabel(this.shipper.country.code)
    }
  },
  mounted() {
    if (this.mySession.id) this.setInitialData()
    if (this.$route.params?.id) {
      this.getBillingDataCompanyById()
    }
    if (!!this.businessDataNotCreated?.length && ['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
      this.setFieldsProperties(this.fieldsOrganization, 'organization_id', 'options', this.businessDataNotCreated, true)
      this.loading.first = false
    }
    if (!!this.shippers?.length && ['marketplace'].includes(this.$session.get('cas_user').role)) {
      this.setFieldsProperties(this.fieldsOrganization, 'shipper_id', 'options', this.shippers, true)
    }
    if (!!this.shipper?.length && !!this.$route.params?.id) {
      this.setDniLabel(this.shipper.country.code)
    }
  },
  methods: {
    setInitialData() {
      const date = new Date()
      this.maxMonth = date.setMonth(date.getMonth() + 2)
      this.fields = [
        { name: 'company_info', useSlot: true, containerClass: 'col-sm-12 container-info' },
        { fieldType: 'FieldInput', name: 'company_id', label: 'ID', useLabel: true, containerClass: 'col-sm-12 col-md-4 container-info', disabled: true, useSkeleton: false },
        { fieldType: 'FieldInput', name: 'company_type', label: 'Tipo', useLabel: true, containerClass: 'col-sm-12 col-md-4 container-info', disabled: true,
          // TODO T20-1125: mover este tooltip al checkbox de "Facturación Directa"
          // tooltip: { text: 'La facturación directa se puede activar en compañías de tipo Seller de Marketplace únicamente.' }
          tooltip: { text: 'Las compañías de tipo "Seller" tienen la opción de habilitar "Facturación Directa".' }
        },
        { fieldType: 'FieldInput', name: 'company_name', label: 'Nombre', useLabel: true, containerClass: 'col-sm-12 col-md-4 container-info', disabled: true },
        {
          fieldType: 'FieldDatepicker', name: 'overdue_payment_limit', label: 'Fecha límite de pago', useLabel: true, containerClass: 'col-sm-12 col-md-4 container-info',
          disabled: true,
          clearable: false,
          propsVCalendar: { 'min-date': new Date(), 'max-date': this.maxMonth },
          datePattern: '/',
          validation: '',
          placeholder: 'dd/mm/yyyy',
          tooltip: { text: 'La "Fecha límite de pago" solo puede ser ingresada cuando la compañía se encuentre "Activa" y con "Motivo de la activación" en "Cuenta activada por compromiso de pago", será considerada para la DESACTIVACIÓN AUTOMATICA de la compañía si una vez cumplida se detectan facturas vencidas, y se limpia al detectarse pago de las deudas vencidas.' }
        },
        { name: 'billing_info', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1', disabled: true },
        { fieldType: 'FieldCheckbox', name: 'directBilling', containerClass: 'col-sm-12 col-md-2 container-info justify-content-center item-content-center', options: [{id: true, text: 'Facturación Directa'}]},
        { fieldType: 'FieldCheckbox', name: 'showInvoices', containerClass: 'col-sm-12 col-md-2 container-info justify-content-center item-content-center', options: [{id: true, text: 'Mostrar facturas'}]},
        { name: 'space', useSlot: true, containerClass: 'col-sm-8 container-info mt-4' },
        { fieldType: 'FieldInput', name: 'taxIdentificationNumber', label: 'TaxId', useLabel: false, useSkeleton: false, containerClass: 'col-sm-2 container-info' },
        { fieldType: 'FieldInput', name: 'business_name', label: 'Razón social', useLabel: true, containerClass: 'col-sm-2 container-info', validation: 'required'},
        { name: 'space', useSlot: true, containerClass: 'col-sm-8 container-info' },
        { fieldType: 'FieldInput', name: 'country', label: 'País', useLabel: true, containerClass: 'col-sm-2 container-info', disabled: true},
        { fieldType: 'FieldInput', name: 'place_name', label: 'Lugar (Comuna)', useLabel: true, containerClass: 'col-sm-12 col-md-4 container-info' },
        { fieldType: 'FieldInput', name: 'city', label: 'Cuidad', useLabel: true, containerClass: 'col-sm-12 col-md-3 container-info'},
        { fieldType: 'FieldInput', name: 'address', label: 'Dirección', useLabel: false, containerClass: 'col-sm-12 col-md-3 container-info' },
        { fieldType: 'FieldInput', name: 'credit_days', useLabel: false, useSkeleton: false, label: 'Días de crédito', type: 'number', containerClass: 'col-sm-2 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'business_activity', useLabel: false, label: 'Giro', containerClass: 'col-sm-2 container-info', validation: 'required' },
        { fieldType: 'FieldCheckbox', name: 'required_oc', containerClass: 'col-sm-2 container-info justify-content-center item-content-center', options: [{id: true, text: 'OC'}]},
        { name: 'table_emails', useSlot: true, containerClass: 'col-sm-12 container-info pt-1' },
        { name: 'contacts', useSlot: true, containerClass: 'col-sm-12 container-info pt-1' }
      ]
      this.schema = [
        { label: 'Nombre', key: 'name' },
        { label: 'Cargo', key: 'jobTitle' },
        { label: 'Mail', key: 'email' },
        { label: 'Telefono', key: 'phoneNumber' },
        { label: 'Acciones', key: 'actions', class: 'text-center', style: { width: '20%' } }
      ]
      this.actions = [
        { action: id => this.editEmail(id), icon: 'Edit2Icon', color: 'primary', text: 'Editar' },
        { action: id => this.confirmDeleteEmail(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
      ]
      this.fieldEmail = [{ fieldType: 'FieldTextarea', name: 'mail', label: 'Email de contacto', containerClass: 'col-sm-12 col-md-6 container-info' }]

      if (!this.$route.params?.id) {
        ['taxIdentificationNumber'].map(el => {
          this.setFieldsProperties(this.fields, el, 'useSkeleton', true)
          this.setFieldsProperties(this.fields, el, 'useLabel', true)
        })
        if (['superadmin', 'admin'].includes(this.$session.get('cas_user').role)) {
          this.getServicesBusinessDataNotCreated()
          this.fieldsOrganization.push(
            {fieldType: 'FieldSelect', name: 'organization_id', label: 'Organización', useLabel: true, clearable: true, change: this.changeOrganization, options: this.businessDataNotCreated, validation: 'required', searchOnType: { fx: this.searchOrganization, nChars: 3, debounce: 600 } }
          )
        }
        this.setCreateBillingSetting()
        this.loading.first = false
      }
      // Condicionamos la variable paramId para que cuando se cree una nueva empresa el valor sea su company_id y con esto llamamos al servicio de listar por company_id y al guardar se editara dicha empresa ya creada
      this.paramId = this.$route.params.id || this.company_id_on_save
      if (this.paramId) {
        this.environment = this.billingService.setEnvironment()
        this.linkHistory = `/manage-billing-data/${this.paramId}/history`
        //Mostrar el formulario del estado de la compañia
        this.fields.splice(this.fields.findIndex(el => el.name === 'company_name') + 1, 0,
          { fieldType: 'FieldRadio', name: 'company_status', label: 'Estado de activación', useLabel: true,  align: 'h', options: this.companyStatusOptions, change: this.setMotiveInput, containerClass: 'col-sm-12 col-md-4'},
          {...this.inputMotive, label: 'Motivo' }
        )
        this.setStatus()
        if (this.activationStatusOptions.length === 0) this.$store.dispatch('fetchService', { name: 'getActivationStatusDataCompany'})
        this.changeToCreate = false
        // Obtenemos la información de la compañia, para validar que tipo es y si debes obtener sus datos por organization o shipper
        this.$store.dispatch('fetchService', {
          name: 'getManageBillingDataCompany',
          params: { company_id: this.paramId },
          hideAlert: true,
          onSuccess: (response) => this.validateCompanyType(response.data),
          onError: (e) => this.onErrorEditManageData(e)
        })
        this.buttonSend = { icon: 'EditIcon', color: 'warning', text: 'Guardar cambios', status: false}
        this.loading.first = false
      }
    },
    setStatus () {
      this.form = {
        company_status: { id: this.manageDataCompany?.company?.isActive ? 1 : 2 },
        status_description: this.manageDataCompany?.company?.activationStatusDesc
      }
    },
    setMotiveInput (name, value) {
      const index = this.fields.findIndex(el => el.name === 'status_description')
      const statusText = value.id === 1 ? 'de la activación' : value === 'clear' ? '' : 'de la inactivación'
      if ((this.allowStatusType && value.id === 1) || (!this.allowStatusType && value.id === 2) || value === 'clear') {
        this.buttonSend.status = false
        this.fields[index] = {
          ...this.inputMotive,
          label: `Motivo ${statusText}`
        }
        this.form.status_description = this.manageDataCompany?.company?.activationStatusDesc
        if (this.manageDataCompany?.company?.activationStatusDesc === 'Compromiso de Pago' && this.$route.params?.id && value.id === 1) this.setOverduePaymentInput()
        this.keyFormRenderManageDataCompany++
      }
      else if ((this.allowStatusType && value.id === 2) || (!this.allowStatusType && value.id === 1)) {
        this.buttonSend.status = true
        this.form.status_description = null
        this.fields[index] = {
          fieldType: 'FieldSelect',
          name: 'status_description',
          label: `Motivo de la ${statusText}`,
          useLabel: true,
          placeholder: `Motivo de la ${statusText}`,
          clearable: true,
          validation: 'required',
          dependency: 'company_status',
          containerClass: 'col-sm-12 col-md-4 container-info mb-0',
          change: this.handleMotiveChange
        }
        this.setFieldsProperties(this.fields, 'status_description', 'options', this.activationStatusOptions[this.allowStatusType ? 'DEACTIVATION' : 'ACTIVATION'].map(data => { return { ...data, text: data.name } }))
      }
      if (this.form.status_description !== 'Compromiso de Pago') this.deleteOverduePaymentInput()

    },
    handleMotiveChange (name, value) {
      value ? this.buttonSend.status = false : this.buttonSend.status = true
      if (!this.allowStatusType && value?.id === 3) {
        this.setOverduePaymentInput()
      } else {
        this.deleteOverduePaymentInput()
      }
    },
    setOverduePaymentInput () {
      this.form.overdue_payment_limit = null
      const index = this.fields.findIndex(el => el.name === 'overdue_payment_limit')
      this.fields[index].disabled = false
      this.fields[index].clearable = true
      this.fields[index].validation = 'requiredDate'
      this.keyFormRender++
    },
    deleteOverduePaymentInput() {
      this.form.overdue_payment_limit = this.$options.filters.dateToFullDate(this.manageDataCompany.overdue_payment_limit) || null
      const indexOverduePayment = this.fields.findIndex(el => el.name === 'overdue_payment_limit')
      this.fields[indexOverduePayment].disabled = true
      this.fields[indexOverduePayment].clearable = false
      this.fields[indexOverduePayment].validation = ''
      this.keyFormRender++
    },
    setCreateBillingSetting(onCreate = true) {
      if (['marketplace'].includes(this.$session.get('cas_user').role)) {
        this.fieldsOrganization.push(
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Shipper', useLabel: true, placeholder: 'Nombre de la empresa', change: this.getServicesBusinessDataNotCreated, clearable: true, validation: 'required' }
        )
        this.$store.dispatch('fetchService', { name: 'getShippersByOrganization', queryParams: {page: 1, paginate_by: 100}, params: { organization_id: this.$session.get('cas_user').organization.id }})
      }
      else if (['seller', 'ecommerce'].includes(this.$session.get('cas_user').role)) {
        this.form = {
          ...this.form,
          company_id: this.$session.get('cas_user').shipper.id,
          company_name: this.$session.get('cas_user').shipper.name1
        }
        if (onCreate) { //Solo caso de que se llegue por el boton de [Crear contacto]
          this.form = {
            ...this.form,
            business_name: this.$session.get('cas_user').shipper.name2
          }
        }
      }
    },
    rutMask (value) {
      //Autoformatear el rut a la hora de ingresar los datos
      return (value.length <= 1) ? value : new String(this.$options.filters.formatRut(value))
    },
    getServicesBusinessDataNotCreated() {
      const queryParams = {
        page: 1, size: 100
      }
      this.$store.dispatch('fetchService', { name: 'getBusinessDataNotCreated', queryParams})
    },
    searchOrganization(searchOnType) {
      const queryParams = {
        'page': 1,
        'size': 100,
        'search': searchOnType,
        'filter[businessDataCreated]': 0,
        'filter[businessDataEnabled]': 1
      }

      return this.baseService.callService('getSearchOrganizationData', queryParams)
        .then(resp => {
          const response = resp.data.map(el => ({...el, text: el.name}))
          return response
        })
        .catch(err => {
          return err
        })
    },
    // Filtro que se puede utilizar para el tipo de compañia ahora esta validando por id
    filterCompany(infoCompany) {
      // Creamos un array de casos especiales de compañias
      const specialCasesOfCompanies = [
        {name: 'Development', id: 2279, type: 'seller'},
        {name: 'Tienda', id: 2582, type: 'seller'},
        {name: 'Development', id: 10, type: 'marketplace'}
      ]
      // Por los momentos se esta filtrando por el id, esto puede cambiarse a filtrar por el type de company
      const filteredCompany = specialCasesOfCompanies.filter(item => {
        return (item.id === infoCompany?.company?.id) || (item.id === infoCompany?.id)
      })
      return filteredCompany.length > 0
    },
    // Validamos que tipo de compañia es
    validateCompanyType (infoCompany) {
      // En este caso esta utilizando la compañia Development y Tienda que en organization no existen por ende llamamos por medio de shipper
      if (!!this.filterCompany(infoCompany)) {
        this.$store.dispatch('fetchService', { name: 'getShipper', params: { shipper_id: this.paramId }})
      }
    },
    saveForm() {
      this.$refs.formRenderManageDataCompany.checkForm()
    },
    onAccept(form, emails) {
      if (!this.validateIfExistsEmails(emails)) return
      const manageDataCompany = {
        taxIdentificationNumber: form.taxIdentificationNumber,
        address: form.address,
        place_name: form.place_name,
        city: form.city,
        business_name: form.business_name,
        business_activity: form.business_activity,
        contacts: this.manageDataCompany.contacts,
        credit_days: form.credit_days ? parseInt(form.credit_days) : parseInt(0),
        required_oc: !!form.required_oc,
        showInvoices: !!form.showInvoices,
        directBilling: !!form.directBilling,
        overdue_payment_limit: this.$options.filters.moment(form.overdue_payment_limit, 'YYYY-MM-DD') || null
      }
      const data = {
        name: 'saveManageBillingDataCompany',
        params: { company_id: parseInt(this.form.company_id)},
        queryParams: { ...manageDataCompany },
        showSuccess: true,
        onSuccess: () => this.savedBillingDataCompany(),
        onError: err => this.errorOnSaveData(err)
      }
      this.$store.dispatch('fetchServiceStatusOnError', data)
    },
    errorOnSaveData(err) {
      const msg = []
      if (err.errors) {
        err.errors.forEach((e) => {
          let message = ''
          message = Array.isArray(e.detail) ? e.detail.join('.<br>') : message = e.detail
          msg.push(message)
        })
      }
      this.$alert(msg.join('.<br>'), null, 'Ocurrió un problema al guardar los datos', '', false)

    },
    savedBillingDataCompany() {
      // Llamamos al setInitialData() para que cambie a modo edición luego de  crear una nueva empresa
      this.company_id_on_save = this.form.company_id
      this.fieldsOrganization = []
      this.setInitialData()
      this.changeOrganization('', null)
    },
    updateStatusCompany () {
      const params = {
        company_id: this.manageDataCompany?.company.id
      }
      const data = {
      }

      if (this.allowStatusType) {
        params.status_type = 'deactivate'
        //params.reason_id = `deactivationId=${this.form.status_description.id}`
        data.deactivationId = this.form.status_description.id
      } else {
        params.status_type = 'activate'
        //params.reason_id = `activationId=${this.form.status_description.id}`
        data.activationId = this.form.status_description.id
      }
      const activateWithDate = params.status_type === 'activate' && this.form.status_description.id === 3
      const deactivateWithDate = params.status_type === 'deactivate' && !!this.overdueDate && this.allowStatusType
      this.baseService.callUploadFileCustomParams('updateManageBillingDataCompanyStatus', data, params)
        .then(response => {
          this.$success(this.$t('msg-exito-actualizar-estado-compania', {company_id: this.manageDataCompany?.company.id, state: this.allowStatusType ? 'desactivado' : 'activado'}))
          this.allowStatusType = this.manageDataCompany?.company?.isActive
          this.setInitialData()
        })
        .catch(err => {
          const title = err?.errors ? err.errors[0].title : 'Ocurrió un problema al actualizar el estado'
          const detail = err?.errors ? err.errors[0].detail : err.message
          const invalidMessage = 'Error al confirmar la activación de la compañía'
          this.$alert(detail, null, title, '', false)
          //Settear o limpiar la fecha limite de pago en caso de error ya que se guardo primero
          if (activateWithDate || deactivateWithDate) {
            //this.form.overdue_payment_limit = this.$options.filters.moment(this.overdueDate, 'YYYY-MM-DD') || null
            //this.form.company_status = { id: this.manageDataCompany?.company?.isActive ? 1 : 2 }
            //this.form.status_description = this.manageDataCompany?.company?.activationStatusDesc
            //this.updateManageDataCompany(this.form, this.rowsEmails, false)
            //this.setMotiveInput('', 'clear')
            if (activateWithDate) this.deleteOverduePaymentInput()
            if (deactivateWithDate) this.setOverduePaymentInput()
          }
          else {
            this.$store.dispatch('fetchService', { name: 'getManageBillingDataCompany', params: { company_id: this.manageDataCompany?.company.id } })
          }
        })
        .finally(() => {
          this.callService = false
        })
    },
    confirmDeleteEmail(_id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), () => this.deleteEmail(_id))
    },
    deleteEmail(id) {
      const deleteRow = this.rowsEmails.find(email => id === email.id)
      deleteRow.action = 'delete'
      this.pruebamilquinientos(deleteRow)
    },
    toogleModalAddContacts() {
      this.$bvModal.show('ModalAddContactsFact')

      // if (this.formEmail.mail === undefined) {
      //   this.showEmptyTextarea = true
      //   return
      // }
      // const expReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
      // let emails = this.formEmail.mail.split(',')
      // const invalidEmails = []
      // emails = emails.map(email => email.split(' ').join('').toLowerCase())
      // emails.map(mails => {
      //   const isValidEmail = expReg.test(mails)
      //   if (isValidEmail) {
      //     const validationEmail = this.rowsEmails?.find(mail => mails === mail.mail)
      //     if (validationEmail) { return this.$alert(this.$t('msg-problema-guardar', { code: 'El email que ha introducido ya esta registrado' })) }
      //     if (this.rowsEmails !== null) {
      //       this.rowsEmails.push({ id: mails, mail: mails })
      //     } else {
      //       this.rowsEmails = []
      //       this.rowsEmails.push({ id: mails, mail: mails })
      //     }
      //     this.formEmail = {}
      //   } else {
      //     this.$alert(this.$t('msg-problema-guardar', { code: 'El email que ha introducido no es válido' }))
      //     invalidEmails.push(mails)
      //     this.formEmail = {mail: invalidEmails.join()}
      //   }
      // })
      // this.keyFormRender++
      // this.showInvalidEmails = false
      // this.showEmptyTextarea = false
    },
    updateManageDataCompany(data, emails, showModalSuccess = true) {
      if (!this.validateIfExistsEmails(emails)) return
      this.buttonSend.status = false
      this.callService = true
      const params = { company_id: parseInt(this.manageDataCompany.company.id) }
      const manage = {
        taxIdentificationNumber: data.taxIdentificationNumber,
        address: data.address || '',
        place_name: data.place_name || '',
        city: data.city || '',
        business_name: data.business_name,
        business_activity: data.business_activity,
        credit_days: parseInt(data.credit_days),
        contacts: this.manageDataCompany.contacts,
        required_oc: !!data.required_oc,
        showInvoices: !!data.showInvoices,
        directBilling: !!data.directBilling
      }
      if (
        (this.manageDataCompany?.company.isActive && this.form.company_status.id !== 1) ||
        (!this.manageDataCompany?.company.isActive && this.form.company_status.id !== 2) ||
        (this.manageDataCompany?.company?.activationStatusDesc === 'Compromiso de Pago' && this.$route.params?.id && data.company_status.id === 1)
      ) {
        manage.overdue_payment_limit = this.$options.filters.moment(data.overdue_payment_limit, 'YYYY-MM-DD') || null
      } else {
        manage.overdue_payment_limit = null
      }
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'updateManageBillingDataCompany', queryParams: manage, params, onSuccess: () => { this.onSuccessUpdatingData(showModalSuccess) }, onError: err => this.errorUpdatingData(err) })
    },
    onSuccessUpdatingData (showModal) {
      if ((this.manageDataCompany?.company.isActive && this.form.company_status.id !== 1) || (!this.manageDataCompany?.company.isActive && this.form.company_status.id !== 2)) {
        Swal.fire({
          title: 'Actualizando estado de la empresa',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading()
          }
        })
        this.updateStatusCompany()
      } else {
        if (showModal) this.$success(this.$t('Se ha guardado correctamente el registro'))
        this.callService = false
      }
    },
    errorUpdatingData(err) {
      const title = err?.errors ? err.errors[0].title : 'Ocurrió un problema al guardar los datos'
      const detail = err?.errors ? err.errors[0].detail : 'Consulte con soporte por favor.'
      this.$alert(this.$t('msg-problema-personalizado', {titleErr: title, detailErr: detail}))
      //
      // TODO T20-1162: limpiar código comentado si ya no es necesario
      //
      //const {subtitle, detail} = this.handlerStandartError(err)
      //this.$alert(`${subtitle}<small>${detail}</small>`, null, 'Ocurrió un problema al guardar los datos')
      //
      // Before BrahyanPro
      // const message = err.errors[0]?.detail ? err.errors.forEach((e) => Array.isArray(e.detail) ? e.detail.join('.<br>') : e.detail).join('.<br>') : Array.isArray(err.message?.data) ? err.message?.data.join('.<br>') : err.errors
      // this.$alert(message, null, 'Ocurrió un problema al guardar los datos', '', false)
      this.callService = false
    },
    saveOrUpdateBillingManageDataCompany(data) {
      if (this.paramId && !this.changeToCreate) {
        this.updateManageDataCompany(data, this.rowsEmails)
      } else {
        this.onAccept(data, this.rowsEmails)
      }
    },
    validateIfExistsEmails(emails) {
      if (emails?.length > 0) {
        this.showInvalidEmails = false
        return true
      } else {
        this.showInvalidEmails = true
        return false
      }
    },
    setFieldsProperties (fields, name, propertyField, value, orgForm = false) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index][propertyField] = value
        orgForm ? this.keyFormRenderOrganizationBilling++ : this.keyFormRenderManageDataCompany++
      }
    },
    resetForm () {
      this.form = {
        ...this.form,
        place_name: null,
        address: null,
        city: null,
        business_activity: null,
        taxIdentificationNumber: null,
        credit_days: null,
        required_oc: null,
        showInvoices: null,
        directBilling: null,
        business_name: null,
        overdue_payment_limit: null
      }
      this.formEmail = {}
      this.rowsEmails = null
      this.keyFormRenderManageDataCompany++
      this.keyFormRender++
    },
    rederizeShippersSelector (value) {
      const queryParams = {
        page: 1,
        paginate_by: 100
      }
      const params = {
        organization_id: value.id
      }
      this.setFieldsProperties(this.fieldsOrganization, 'shipper_id', 'useSkeleton', true, true)
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganization', queryParams, params, onSuccess: () => this.setFieldsProperties(this.fieldsOrganization, 'shipper_id', 'useSkeleton', false)})
      this.fieldsOrganization.push(
        {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Shipper', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, change: this.changeShipper, validation: 'required' }
      )
    },
    changeOrganization (name, value) {
      const index_shipper = this.fieldsOrganization.findIndex(el => el.name === 'shipper_id')
      this.fieldsOrganization = this.fieldsOrganization.filter(el => el.name !== 'shipper_id')
      this.resetForm()
      if (!value?.id) return null
      // Si se detecta que debe llamar al Shipper
      if (!!this.filterCompany(value)) {
        this.rederizeShippersSelector(value, index_shipper)
      }
      // En caso contrario, se llama organization
      else {
        this.onSuccessOrganization(this.formOrganization.organization_id)
      }
    },
    onSuccessOrganization (data) {
      this.form = {
        ...this.form,
        company_id: data?.id,
        company_name: data?.name,
        company_type: data?.type?.platformName,
        country: data?.country?.name
        // Falta setear el valor de la Razon social
        /*business_name: 'Razon Social Prueba'*/

      }
      this.setDniLabel(data?.country?.code || 'WORD_DNI')
      if (data?.id) {
        const param_id = data.id
        this.$store.dispatch('fetchService', {
          name: 'getManageBillingDataCompany',
          params: { company_id: param_id },
          hideAlert: true,
          onSuccess: this.onSuccessManageDataResp,
          onError: this.onErrorManageDataResp
        })
      }
    },
    changeShipper (name, value) {
      if (value === null) {
        this.resetForm()
      } else {
        this.form = {
          ...this.form,
          business_name: value.name2,
          company_id: value.id,
          company_name: value.name1,
          country: value.country.name
        }
        this.setDniLabel(value.country.code)
        const param_id = value.id
        this.$store.dispatch('fetchService', { name: 'getManageBillingDataCompany', params: { company_id: param_id }, hideAlert: true, onSuccess: () => this.onSuccessManageDataResp(), onError: (err) => this.onErrorManageDataResp(err) })
      }
    },
    onSuccessManageDataResp() {
      if (!this.$route.params?.id) this.$alert('Esta compañía tiene datos de facturación', () => this.changeShipper('', null))
    },
    onErrorManageDataResp(err) {
      if (!!this.$route.params?.id) this.$alert(this.$t('msg-problema-cargar-datos'), {code: err})
    },
    onErrorEditManageData (e) {
      this.setManageDataCompany()
      const { id, type } = this.companyDataBilling
      const alertMsg = type?.platformName === 'Seller' ? 'seller' : 'mk-ecomerce'
      //En caso de que por alguna razón la llamada al servicio aún no este lista con los datos, no muestre "undefined" para el id o el typo
      const title = (id && type) ? `La compañía ${id} es de tipo ${type?.platformName}:` : ''
      this.$confirm(this.$t(`msg-alerta-editar-manage-data-${alertMsg}`), () => {
        this.changeToCreate = true
      }, title, 'OK', 'Cancelar', false, () => this.blockForm())
    },
    getBillingDataCompanyById() {
      const params = {
        id: this.$route.params.id
      }
      this.$store.dispatch('fetchService', { name: 'getBillingDataCompanyById', params, onSuccess: (response) => this.companyDataBilling = response.data })
    },
    setManageDataCompany() {
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user').role)) {
        this.form = {
          ...this.form,
          company_id: this.companyDataBilling.id,
          company_type: this.companyDataBilling.type?.platformName,
          company_name: this.companyDataBilling.name,
          country: this.companyDataBilling?.country?.name
        }
        this.setFieldsProperties(this.fields, 'taxIdentificationNumber', 'useSkeleton', false)
        this.setDniLabel(this.companyDataBilling.country?.code || 'WORD_DNI')
        this.buttonSend = { ...this.buttonSend, text: 'Crear datos de facturación' }
      } else {
        this.setCreateBillingSetting(false)
      }
    },
    setDniLabel(countryCode) {
      const texts = this.$i18nImport(`${countryCode.toLowerCase()}.es`)[`${countryCode.toLowerCase()}.es`]
      const indexDNI = this.fields.findIndex(el => el.name === 'taxIdentificationNumber')
      this.fields[indexDNI].label = texts?.TaxId
      this.fields[indexDNI].placeholder = texts?.FORMAT_TAX_ID
      this.fields[indexDNI].validation = `required|taxIdValidator:${countryCode.toLowerCase()}`
      this.fields[indexDNI].useLabel = false
      this.fields[indexDNI].useSkeleton = false
      if (countryCode === this.country.chile) this.fields[indexDNI].specialVerification = this.rutMask
      this.keyFormRender++
    },
    blockForm () {
      this.buttonSend = { ...this.buttonSend, status: true}
      this.disableAddEmail2Btn = true
      this.fields.map(el => {
        this.setFieldsProperties(this.fields, el.name, 'disabled', true)
      })
      this.fieldEmail.map(el => {
        this.setFieldsProperties(this.fieldEmail, el.name, 'disabled', true)
      })
      //Dependiendo el tipo de usurio se ira a un lugar u otro
      const url = ['admin'].includes(this.$session.get('cas_user')?.role) ? '/manage-billing-data' : ''
      this.$router.push({path: url})
    },
    editEmail(id) {
      const editRow = this.rowsEmails.find(email => id === email.id)
      this.formNewContact = editRow
      this.$bvModal.show('ModalAddContactsFact')
    },
    pruebamilquinientos(data) {
      const { action, id: IdContact, ...rest } = data
      if (!this.manageDataCompany.contacts) {
        this.manageDataCompany.contacts = []
      }
      switch (action) {
      case 'create':
        // Agrega un nuevo contacto
        this.manageDataCompany.contacts.push(rest)
        break

      case 'edit':
        this.manageDataCompany.contacts = this.manageDataCompany.contacts.map(contact => contact.email === IdContact ? rest : contact)
        break

      case 'delete':
        this.manageDataCompany.contacts = this.manageDataCompany.contacts.filter(contact => contact.email !== rest.email)
        break

      default:
      }

      this.rowsEmails = this.manageDataCompany.contacts.map(contact => {
        return {
          id: contact.email,
          ...contact
        }
      })
      this.$bvModal.hide('ModalAddContactsFact')
    },
    resetFormContacts() {
      this.formNewContact = {}
    }
  }
}
</script>


<style scoped>
.email_button {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}
.required-feedback {
  font-size: 0.857rem;
  color: #ea5455;
  margin-top: 0.25rem;
}
.border-error {
  border: var(--danger) 1px solid;
}

h2.swal2-title{
  font-size: 1.3rem !important;
}
</style>
