<template>
    <div class="row">    
      <div v-for="(field, index) in fieldSkeleton" :key="`${index}-skeleton-form`"
          :class="[
          field.containerClass? field.containerClass : 'col-sm-12 col-md-4 container-info'
          ]">
          <b-skeleton v-if="field.type !='Section'" width="20%"/>    
          <hr v-if="field.type ==='Section'"/>  
          <div v-else-if="field.type === 'FieldRadio'" class="d-flex align-content-center">
            <b-skeleton class="mr-1" type="avatar" height="20px" width="20px"/> <b-skeleton class="align-self-center mb-0" width="30%"/> 
          </div>
          <div v-else-if="field.type === 'FieldCheckbox'" class="d-flex align-content-center">
            <b-skeleton class="mr-1" height="20px" width="20px"/> <b-skeleton width="30%" height="20px"/> 
          </div>
          <b-skeleton v-else-if="field.type === 'FieldTextarea'" type="input" height="100px"/>
          <b-skeleton v-else type="input" height="38px"/>
      </div>
      <div class="container mt-3 row">        
        <div class="col-sm-12">
          <b-skeleton width="40%"/>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-skeleton height="90px"/>
        </div>
        <div class="col-sm-12 col-md-4">
          <b-skeleton height="90px"/>
        </div>
        <div class="col-sm-12 col-md-2">
          <b-skeleton type="button" width="100%"></b-skeleton>
        </div>
      </div>
      <div class="mt-1 col-12">
          <b-skeleton type="button" width="170px"></b-skeleton>
      </div>     
  </div>
</template>
<script>

export default {
  name: 'form-skeleton',
  data () {
    return {
      fieldSkeleton: [
        { type: 'FieldInput' },
        { type: 'FieldInput' },
        { type: 'FieldInput' },
        { type: 'Section', containerClass:'col-12' },
        { type: 'FieldInput' },
        { type: 'FieldInput' },
        { type: 'FieldInput' },
        { type: 'FieldInput' },
        { type: 'FieldSelect' },
        { type: 'FieldInput' },
        { type: 'FieldInput'},
        { type: 'FieldCheckbox' },
        { type: 'FieldInput'}
      ]
    }
  }
}
</script>
<style lang="scss">
</style>
