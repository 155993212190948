<template>
  <b-modal
      id="ModalAddContactsFact"
      :title="editMode ? 'Editar contacto' : 'Añadir contacto'"
      size="lg"
      centered
      no-close-on-backdrop
      hide-footer
      @hide="close"

  >
    <form-render :form.sync="formNewMKP"
                 :fields="fieldsNewMKP"
                 containerButtonsClass="col-sm-12"
                 :key="keyFormRenderNewMKP"
                 ref="formRenderNewMKP"
                 @send="addNewContact"
    />
    <div>
      <b-button class="float-right mt-1 mb-1 col-md-3 " variant="outline-primary" @click="ok" size="sm" :disabled="loading.someMail">
        <span v-if="!loading.addingMKP" class="mb-1"> {{editMode ? 'Editar' : 'Añadir'}}</span>
        <span class="mt-1 p-2" v-if="loading.addingMKP"><b-spinner small  /> </span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'ModalAddContacts',
  props: ['dataFact', 'formNewContact'],
  data() {
    return {
      fieldsNewMKP: [],
      formNewMKP: {},
      keyFormRenderNewMKP: 0,
      editMode: false,
      loading: {
        addingMKP: false,
        someMail: false
      },
      safeMail: ''
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    formNewContact() {
      if (this.formNewContact.id) {
        this.editMode = true
        this.formNewMKP = {
          ...this.formNewContact
        }
        this.safeMail = this.formNewMKP.email
      } else {
        this.editMode = false
        this.formNewMKP = {}
      }
    }
  },
  methods: {
    setInitialData() {
      this.fieldsNewMKP = [
        { fieldType: 'FieldInput', useLabel: true, name: 'name', label: this.$t('Nombre'), validation: 'required', containerClass: 'col-12 col-md-12  mb-2',  clearable: true},
        { fieldType: 'FieldInput', useLabel: true, name: 'jobTitle', label: this.$t('Cargo'), containerClass: 'col-12 col-md-12 mb-2', clearable: true},
        { fieldType: 'FieldInput', useLabel: true, name: 'email', label: this.$t('Correo'), change: this.onChangeEmail, containerClass: 'col-12 col-md-12 mb-2', validation: 'required|email', clearable: true, msgError: '' },
        { fieldType: 'FieldInput', useLabel: true, name: 'phoneNumber', label: this.$t('Teléfono'), containerClass: 'col-12 col-md-12 mb-2', clearable: true}
      ]
    },
    onChangeEmail() {
      const existEmailInContact = this.dataFact?.contacts?.some(contact => contact.email === this.formNewMKP.email)
      const isSafeMail = this.safeMail === this.formNewMKP.email
      if (existEmailInContact && !isSafeMail) {
        this.fieldsNewMKP.find(field => field.name === 'email').msgError = 'El correo ya existe'
        this.loading.someMail = true
      } else {
        this.fieldsNewMKP.find(field => field.name === 'email').msgError = ''
        this.loading.someMail = false
      }
    },

    addNewContact() {
      this.loading.addingMKP = true
      this.formNewMKP.action = this.editMode ? 'edit' : 'create'
      const keys = ['jobTitle', 'phoneNumber']
      keys.forEach(key => {
        if (this.formNewMKP[key] === '' || this.formNewMKP[key] === undefined || this.formNewMKP[key] === null) {
          this.formNewMKP[key] = null
        }
      })
      this.$emit('completeData', this.formNewMKP)
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderNewMKP.checkForm()
    },
    close() {
      this.loading.addingMKP = false
      this.fieldsNewMKP.find(field => field.name === 'email').msgError = ''
      this.formNewMKP = {}
      //Emitir evento para cerrar el modal
      this.$emit('resetForm')
    }
  }

}
</script>

<style lang="scss">
.invalid-mail-company{
  border-color: red;
}
</style>
